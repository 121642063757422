import React from 'react';
import '../styles/InfoItem.css';

const InfoItem = ({ label, value }) => {
    return (
        <div className="info-item">
            <h4>{label}</h4>
            <p>{value}</p>
        </div>
    );
};

export default InfoItem;
