import React from 'react';
import Chart from 'react-apexcharts';
import '../styles/Tokenomics.css'; // Ensure you have this CSS file

const Tokenomics = () => {
    const chartOptions = {
        chart: {
            type: 'pie',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            }
        },
        labels: ['Development', 'Marketing', 'Reserve', 'Team', 'Public Sale'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: '100%'
                },
                legend: {
                    position: 'bottom',
                    offsetY: 0,
                    labels: {
                        colors: 'black' // Adjust for better visibility on mobile
                    }
                }
            }
        }],
        legend: {
            position: 'right',
            offsetY: 0,
            height: 230,
            labels: {
                colors: 'white'
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#fff']
            }
        },
    };

    const series = [20, 30, 10, 25, 15]; // Your data

    return (
        <div id="tokenomics-page">
            <div id="chart">
                <h1>Token Distribution</h1>
                <Chart options={chartOptions} series={series} type="pie" width="100%" />
            </div>
        </div>
    );
};

export default Tokenomics;
