/*
import React from 'react';
import LandingPage from './pages/LandingPage'; // Adjust the path as necessary

const App = () => {
  return (
    <LandingPage />
  );
}

export default App;
*/


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Tokenomics from './pages/Tokenomics';
import Roadmap from './pages/Roadmap';
import EarningsDashboard from './pages/EarningsDashboard';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// 1. Get projectId
const projectId = '878a06a4122b4d190e486ae5e2c2da55'

// 2. Set chains
const mainnet = {
  chainId: 137,
  name: 'Polygon Mainnet',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.com/',
  rpcUrl: 'https://polygon-rpc.com/'
}

// 3. Create a metadata object
const metadata = {
  name: 'MANNY',
  description: 'MANNY on Polygon',
  url: 'https://www.mannytoken.xyz', // origin must match your domain & subdomain
  icons: ['https://www.mannytoken.xyz/static/media/manny_pfp2.9014a942cc9ef32bbe86.png']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tokenomics" element={<Tokenomics />} />
          <Route path="/roadmap" element={<Roadmap />} />
          <Route path="/earnings-dashboard" element={<EarningsDashboard />} />
        </Routes>
    </Router>
  );
}

export default App;