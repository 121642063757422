import React from 'react';
import styles from '../styles/HowToBuy.module.css'; // Make sure you have the corresponding CSS file
import simpleswaplogo from '../images/simpleswaplogo.png'
import wallet from '../images/wallet2.png'
import send from '../images/send.png'
import matic from '../images/matic_howto.png'
import Logo from '../images/manny_pfp2.png';

const HowToBuy = () => {

  return (
    <div className={styles.howToBuyContainer}>
      <h2 className={styles.howToBuyTitle}>How To Buy</h2> {/* Big Title Here */}
        <div className={styles.card}>
          <img className={styles.cardIcon}/>
          <h3>Need to Swap Tokens to Polygon?</h3>
          <div className={styles.buttonContainer}>
            <div className={styles.containerButton}>
            <a href="https://swap.mannytoken.xyz" 
                className={styles.interactiveButton} 
                target="_blank" 
                rel="noopener noreferrer">
                Swap for MATIC
            </a>
            </div>
        </div>
        </div>
      <div className={styles.cardIframe} id="swapSection"> {/* This ID matches the href of the button */}
        <div className={styles.cardTitle}>
            <img src={Logo} alt="Logo" className={styles.navLogo} />
            <h3>Swap $MATIC for $MANNY</h3>
        </div>
        <iframe
            width="400"
            height="650"
            frameborder="0"
            allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *"
            src="https://flooz.xyz/embed/trade?swapDisabled=false&swapToTokenAddress=0x3cb8697a6b0404aa55A3747b2E56fd73A9F44ABd&swapLockToToken=false&onRampDisabled=true&onRampAsDefault=false&onRampTokenAddress=matic&stakeDisabled=true&network=polygon&lightMode=false&primaryColor=%2314f195&backgroundColor=transparent&roundedCorners=10&padding=20&refId=jMgdGp"
        ></iframe>
        <div style={{textAlign: 'center'}}><p className={styles.orText}>Or</p></div>
        <div className={styles.buttonContainer}>
            <div className={styles.containerButton}>
            <a href="https://quickswap.exchange/#/swap?outputCurrency=0x3cb8697a6b0404aa55A3747b2E56fd73A9F44ABd" 
                className={styles.interactiveButton} 
                target="_blank" 
                rel="noopener noreferrer">
                Buy on Quickswap
            </a>
            </div>
        </div>
      </div>
    </div>
  );
};

export default HowToBuy;
