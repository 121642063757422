import React, { useEffect } from 'react'; // Import useEffect
import '../styles/Roadmap.css'; // Ensure this CSS file is updated accordingly
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const milestones = [
    {
        date: 'Phase 1',
        event: '$MANNY Token Launch',
        details: ['Initial Token Offering', 'Burn LP Tokens', 'Dextools update', 'CoinGecko Listing', 'CMC Listing']
    },
    {
        date: 'Phase 2',
        event: 'Reflection Tracking Dashboard',
        details: ['Reflection Earnings Dashboard', 'Expansion of Marketing Efforts', 'Further Development of Utilities']
    },
    {
        date: 'Phase 3',
        event: 'Bridgeless Swap Utility',
        details: ['Bridgeless Swap', 'Simple asset swap to Polygon', 'Develop Revenue Share Utility']
    },
    {
        date: 'Q4 2024',
        event: 'Revenue Share Utility',
        details: ['Revenue Share from Swap Utility','Utility to Claim Revenue Share', 'Further Ecosystem Development', 'Plenty More...']
    },
    // ... Add more milestones
];

const Timeline = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 }); // Initialize AOS with your desired settings
    }, []);

    return (
        <div className="timeline-container">
            <h2 className="timeline-title">👑 $MANNY's Map To Riches 💰</h2>
            <ul className="timeline">
                <li className="timeline-item-left" >
                    <div className="timeline-content">
                        <h3><span style={{fontWeight: 'bold'}}>Phase 1:</span> Rise of $MANNY</h3>
                        <p><span style={{fontWeight: 'bold'}}>$MANNY Token Launch: </span>Witness the ascension of King $MANNY on Polygon</p>
                        <p><span style={{fontWeight: 'bold'}}>Initial Token Offering: </span>Secure your royal position with first access to $MANNY tokens</p>
                        <p><span style={{fontWeight: 'bold'}}>LP Burn: </span>Strengthen the kindom's foundation by securing the liquidity pool</p>
                        <p><span style={{fontWeight: 'bold'}}>Dextools Update: </span>Uniting forces with the realm's top DeFi app</p>
                        <p><span style={{fontWeight: 'bold'}}>CoinGecko Listing: </span>Rise to prominence as $MANNY gains recognition</p>
                        <p><span style={{fontWeight: 'bold'}}>CMC Listing: </span>Establish presence in the vast crypto ocean with CoinMarketCap</p>
                    </div>
                </li>
                <li className="timeline-item" >
                    <div className="timeline-content">
                        <h3><span style={{fontWeight: 'bold'}}>Phase 2:</span> Empowerment</h3>
                        <p><span style={{fontWeight: 'bold'}}>Reflection Tracking: </span>Monitor royal earnings on the reflection tracking dashboard</p>
                        <p><span style={{fontWeight: 'bold'}}>Marketing Expansion: </span>Unleash the Royal Marketing Blitz. Ignite the kingdom with $MANNY's majestic reign</p>
                        <p><span style={{fontWeight: 'bold'}}>Utility Development: </span>Enrich the royal arsenal with new utility development</p>
                    </div>
                </li>
                <li className="timeline-item-left" >
                    <div className="timeline-content">
                        <h3><span style={{fontWeight: 'bold'}}>Phase 3:</span> Expansion</h3>
                        <p><span style={{fontWeight: 'bold'}}>Bridgeless Swap Utility: </span> Seamlessly traverse blockchain realms with our bridgeless swap. Experience effortless asset transfers to and from Polygon</p>
                    </div>
                </li>
                <li className="timeline-item" >
                    <div className="timeline-content">
                        <h3><span style={{fontWeight: 'bold'}}>Phase 4:</span> Enrichment</h3>
                        <p><span style={{fontWeight: 'bold'}}>Bridgeless Swap Revenue Share: </span>Join the kingdom's prosperity and claim your rightful share. Earn profit and revenue share on every public use of the bridgeless swap</p>
                        <p><span style={{fontWeight: 'bold'}}>Revenue Claim Utility: </span>Access royal earnings from the bridgeless swap effortlessly</p>
                        <p><span style={{fontWeight: 'bold'}}>Ecosystem Development: </span>Expand borders and explore new opportunities</p>
                        <p><span style={{fontWeight: 'bold'}}>Much More to Come: </span>Stay tuned for exciting developments in the $MANNY empire</p>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default Timeline;
