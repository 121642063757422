import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import styles from '../styles/Navbar.module.css';
import Logo from '../images/manny_pfp2.png';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
    setIsMobile(false); // Close the menu on clickzz
  };

  return (
    <nav className={styles.navbar}>
      <a href="/"><img src={Logo} alt="Logo" className={styles.navLogo} /></a>
      <div className={styles.mobileMenuIcon} onClick={() => setIsMobile(!isMobile)}>
        {isMobile ? <FaTimes size={36} /> : <FaBars size={36} />}
      </div>
      <div className={`${styles.navLinks} ${isMobile ? styles.navActive : ""}`}>
        {isMobile && (
          <Link to="/" className={styles.navLink} onClick={() => setIsMobile(false)}>Home</Link>
        )}
        <a onClick={() => scrollToSection('tokenomics')} className={styles.navLink}>Tokenomics</a>
        <a onClick={() => scrollToSection('about')} className={styles.navLink}>About</a>
        <a onClick={() => scrollToSection('roadmap')} className={styles.navLink}>Roadmap</a>
        <a href="#swapSection" className={styles.navLink}>Swap</a>
        <Link to="/earnings-dashboard" className={styles.navLink}>Earnings</Link>
      </div>
    </nav>
  );
};

export default Navbar;
