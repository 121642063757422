import React from 'react';
import '../styles/About.css'; // Import the CSS file for styling

const About = () => {
  return (
    <div className="about-container">
      <h2 className="about-title">King Manny 👑 the Manatee</h2>
      <p className="about-text">
        Meet King <span style={{fontWeight: "Bold"}}>$MANNY</span>,
        Polygon's trailblazing <span style={{fontWeight: "Bold"}}>Self-Reflection</span> utility token where holders earn passive income on each transaction of $MANNY token. Born in the vast expanse of the Polygon Seas, King $MANNY stands apart by providing genuine utility to the network while effortlessly rewarding holders. Join the reign of King $MANNY and shape the future of Polygon.
      </p>
    </div>
  );
};

export default About;
