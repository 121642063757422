import React from 'react';
import { motion } from 'framer-motion';
import styles from '../styles/Reflection.module.css'; // Ensure this path is correct
import TokenImage from '../images/manny_pfp2.png'; // Adjust the path as needed

const TokenTransactionDemo = () => {
    const arrowAnimation = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: {
            pathLength: 1,
            opacity: 10,
            transition: { duration: 3, ease: "easeInOut", repeat: Infinity, repeatDelay: 1 }
        }
    };

    // Adjust this for the starting position of the token to match the start of the arrow path
    const tokenAnimationStart = {
        hidden: { x: 190, y: 100, opacity: 0 }, // Adjust x and y to position at the start of the arrow path
        visible: {
            x: 190, // End x position to match the arrow's start point
            y: 300, // End y position to simulate moving towards the holder
            opacity: 100,
            transition: { duration: 3, repeat: Infinity, repeatDelay: 1, ease: "easeInOut" }
        }
    };
    // Animation for the arrow from buyer to transaction
    const buyerToTransactionAnimation = {
        ...arrowAnimation,
        visible: {
            ...arrowAnimation.visible,
            transition: { ...arrowAnimation.visible.transition, delay: 0 } // Start immediately
        }
    };

    return (
        <div className={styles.cardContainer}> {/* Wrap your component content in cardContainer */}
            <div className={styles.container}>
                <h2 className={styles.title}>How Reflection <br></br>Payments Work</h2>

                <div className={styles.transactionBlock}>
                    <div className={styles.buyerIcon}>🧑💰 Buyer or Seller</div>
                </div>
                <svg className={styles.arrowsBuyer} viewBox="0 0 400 100">

                    {/* Arrow animation */}
                    <motion.path
                        fill="none"
                        stroke="white"
                        strokeWidth="8"
                        d="M200,0 L200,150"
                        variants={arrowAnimation}
                        initial="hidden"
                        animate="visible"
                    />
                </svg>
                <div className={styles.transactionBlock}><span style={{fontSize: "1.8em"}}>$MANNY Transaction Breakdown:</span><br></br>
                    <ul>
                        <li>5% Tax on Buy/Sell</li>
                        <li>1% Sent to Operations Wallet</li>
                        <li>4% of Tax Distributed to $MANNY holders 💰</li>
                    </ul>
                </div>
                <svg className={styles.arrows} viewBox="0 140 400 100">
                    <motion.image
                            href={TokenImage}
                            variants={tokenAnimationStart}
                            initial="hidden"
                            animate="visible"
                            width="50"
                            height="50"
                            x={-15} // Adjust x offset to center the image on the path
                            y={-15} // Adjust y offset to center the image on the path
                        />
                    {/* Arrows pointing to the holders - now with straight lines */}
                    <motion.path
                        fill="none"
                        stroke="white"
                        strokeWidth="5"
                        d="M200,100 L200,230" // Direct line to Holder 2
                        variants={arrowAnimation}
                        initial="hidden"
                        animate="visible"
                    />
                </svg>

                <div className={styles.holders}>
                    <div className={styles.holder}>Holder<span className={styles.holderBag}>💰</span></div>
                </div>
            </div>
            <div className={styles.containerBox}>
                <a href="https://whitepaper.mannytoken.xyz" className={styles.jumpButton}>Whitepaper</a> {/* Button to jump to the iframe section */}
            </div>
        </div>
    );
};

export default TokenTransactionDemo;
