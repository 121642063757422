import React from 'react';
import InfoItem from './InfoItem'; // Import the InfoItem component
import '../styles/Tokenomics_bar.css'; // Import the CSS for styling

const Tokenomics_bar = () => {
    const tokenomicsData = [
        { label: 'Total Supply', value: '1 Trillion' },
        { label: 'Max Wallet', value: '4%' },
        { label: 'Buy Tax', value: '3%' },
        { label: 'Sell Tax', value: '3%' },
        // ... add more tokenomics data here
    ];

    return (
        <div className="tokenomics-bar-container">
            <h2 className="tokenomics-title">Tokenomics Overview</h2>
            <div className="tokenomics-details-container">
                {tokenomicsData.map((item, index) => (
                    <InfoItem key={index} label={item.label} value={item.value} />
                ))}
            </div>
        </div>
    );
};

export default Tokenomics_bar;

