import React from 'react';
import { FaTwitter, FaTelegramPlane } from 'react-icons/fa';
import styles from '../styles/Footer.module.css'; // Make sure to create this CSS module
import ChainFactory from '../images/chainfactory-badge-black.png';

const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.socialLinks}>
        <a href="https://twitter.com/mannytoken" target="_blank" rel="noopener noreferrer" className={styles.socialIcon}>
          <FaTwitter />
        </a>
        <a href="https://t.me/mannytoken" target="_blank" rel="noopener noreferrer" className={styles.socialIcon}>
          <FaTelegramPlane />
        </a>
      </div>
      <p className={styles.copyText}>© {new Date().getFullYear()} Manny the Manatee | BlockForge LLC. All Rights Reserved.</p>
        <img className={styles.chainFactoryLogo} src={ChainFactory} />
    </footer>
  );
};

export default Footer;
