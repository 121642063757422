import React, { useEffect } from 'react';
import styles from '../styles/Home.module.css';
import Navbar from '../components/Navbar';
import Tokenomics_Bar from './Tokenomics_bar';
import TokenReflectionDemo from './Reflection';
import About from './About';
import Roadmap from './Roadmap';
import HowToBuy from './HowToBuy'; // Adjust the import path as needed
import Footer from './Footer';
import { FaTwitter, FaTelegramPlane } from 'react-icons/fa'; // Importing Twitter and Telegram icons
import PolygonLogo from '../images/polygon-matic-logo.svg';
import MannyBanner from '../images/Manny_Banner3.png';


const Home = () => {

  const scrollToIframe = () => {
    // Scrolls the window 200px downs
    window.scrollBy(0, 1000);
  };

  const contractAddress = "0x3cb8697a6b0404aa55A3747b2E56fd73A9F44ABd";

  const copyToClipboard = () => {
    if (navigator.clipboard && window.isSecureContext) {
      // Navigator clipboard api method'
      navigator.clipboard.writeText(contractAddress).then(() => {
        alert("Contract address copied to clipboard!");
      }, (err) => {
        console.error("Failed to copy: ", err);
      });
    } else {
      // Text area method
      let textArea = document.createElement("textarea");
      textArea.value = contractAddress;
      // Make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        alert("Contract address copied to clipboard!");
      } catch (err) {
        console.error('Failed to copy: ', err);
      }

      document.body.removeChild(textArea);
    }
  };


  return (
    <div  id="home" style={{ height: '100%' }}>

      <Navbar />

      {/* Include ChainFactory text and logo next to Polygon logo */}
      <div className={styles.chainFactoryLaunch}>
        <img src={PolygonLogo} alt="Polygon Logo" className={styles.logo} />
      </div>
      <div className={styles.ticker}>$MANNY</div>

      <div className={styles.mannyBannerContainer}>
        <img src={MannyBanner} alt="Manny" className={styles.mannyBanner} />
      </div>

      <div className={styles.sloganContainer}>
        <h2 className={styles.slogan}>Hold the Crown👑<br></br> Share the Wealth💰</h2>
      </div>


      <div className={styles.socialLinks}>
            <a href="https://twitter.com/mannytoken" className={styles.socialIcon}><FaTwitter /></a>
            <a href="https://t.me/mannytoken" className={styles.socialIcon}><FaTelegramPlane /></a>
        </div>

      <div style={{ marginTop: '0px', textAlign: 'center', padding: '0px' }}>
        <img src={require('../images/manny_10_nobackground.png')} alt="Token" 
             style={{ maxWidth: '500px', margin: '0px auto' }} 
             className={styles.hoverImage} />

        {/* Token Name with Ellipsoid */}
        <div className={styles.containerBox}>
          <div className={styles.ellipsoid}>
            <h1 className={styles.token_ticker}>$MANNY</h1>
            <img src={PolygonLogo} alt="Polygon Logo" className={styles.polygonLogo} />
          </div>
        </div>

        {/* Contract Address with Ellipsoid */}
        <div className={styles.containerBox} onClick={copyToClipboard}>
          <div className={styles.ellipsoid}>
            <p><span style={{fontWeight: "Bold"}}>Contract Address</span>: {contractAddress}</p>
          </div>
        </div>
        </div>


        <div id="about" style={{ margin: '0 auto', marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
          <About />
        </div>

      {/* Interactive Buttons */}
      <div className={styles.buttonContainer}>
        <div className={styles.containerButton}>
          <a href="https://quickswap.exchange/#/swap?outputCurrency=0x3cb8697a6b0404aa55A3747b2E56fd73A9F44ABd" 
            className={styles.interactiveButton} 
            target="_blank" 
            rel="noopener noreferrer">
            Buy on Quickswap
          </a>
        </div>
        <div className={styles.containerButton}>
          <a href="https://www.dextools.io/app/polygon/pair-explorer/0x3cb8697a6b0404aa55A3747b2E56fd73A9F44ABd" 
            className={styles.interactiveButton} 
            target="_blank" 
            rel="noopener noreferrer">
            Dextools Chart
          </a>
        </div>
      </div>


      <div id="tokenomics" style={{ marginTop: '30px', display: 'block', justifyContent: 'center' }}>
        <Tokenomics_Bar />
      </div>

      <div id="reflection" style={{ marginTop: '30px', display: 'block', justifyContent: 'center' }}>
        <TokenReflectionDemo />
      </div>

      <div>
        <HowToBuy />
      </div>

      <div id="roadmap" style={{ marginTop: '30px', display: 'block', justifyContent: 'center' }}>
        <Roadmap />
      </div>

      <Footer />

      {/* Disclaimer Section */}
      <div className={styles.disclaimerSection}>
        <p className={styles.disclaimerText}>
        This meme & utility token is for entertainment purposes only. Cryptocurrency investments involve risks. Always do your research and consider potential volatility before investing. The BlockForge LLC team is not responsible for financial losses and does not guarantee any financial gains for investors of this token.
        </p>
      </div>

    </div>
  );
};

export default Home;
