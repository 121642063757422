import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from './Footer';
import styles from '../styles/EarningsDashboard.module.css';
import contractABI from '../abi/manny.json';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits } from 'ethers-v6'


const contractAddress = "0x3cb8697a6b0404aa55a3747b2e56fd73a9f44abd";
const alchemyRpcUrl = "https://polygon-mainnet.g.alchemy.com/v2/HAJT05_7OfHY_LpRB60DWqfotUXc4l4l";

const EarningsDashboard = () => {
    const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()
    const [dividendsDistributed, setDividendsDistributed] = useState('');
    const [balance, setBalance] = useState('');


    const fetchData = async (address) => {
        try {

            console.log(address)
            const ethersProvider = new BrowserProvider(walletProvider)
            const signer = await ethersProvider.getSigner()

            const contract = new Contract(contractAddress, contractABI, signer);

            const distributed = await contract.getDividendsDistributed(address);
            setDividendsDistributed(formatUnits(distributed));

            const balanceOf = await contract.balanceOf(address);
            setBalance(formatUnits(balanceOf));
        } catch (error) {
            console.error("Error fetching data from the contract", error);
        }
    };

    useEffect(() => {
        if (address) {
            fetchData(address);
        }
    }, [address]); // This effect depends on `address`

    const formatNumber = (number) => {
        const parsedNumber = parseFloat(number);
        if (isNaN(parsedNumber)) return '0';
        return parsedNumber.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    const formattedBalance = formatNumber(balance);
    const formattedDividendsDistributed = formatNumber(dividendsDistributed);
    const growthPercentage = formatNumber((parseFloat(dividendsDistributed) / (parseFloat(balance) - parseFloat(dividendsDistributed)) * 100).toFixed(2));


    return (
        <div>
            <Navbar />
            <div className={styles.mainContent}>
                <div className={styles.dashboardContainer}>

                        <div className={styles.walletAddressDisplay}>
                            <w3m-button size="md" style={{margin: '0 auto'}} />
                        </div>
                        <div className={styles.cardsContainer}>
                            <div className={styles.buttonContainer}>
                                <div className={styles.containerButton}>
                                    <div className={styles.interactiveButton} >
                                        <h3>Your Balance</h3>
                                        <p><span style={{fontWeight: 'bold', color: '#14f195'}}>{formattedBalance}<br></br> $MANNY</span></p>
                                        </div>
                                    </div>
                            </div>
                            <div className={styles.buttonContainer}>
                                <div className={styles.containerButton}>
                                    <div className={styles.interactiveButton} >
                                        <h3>$MANNY Reflection Earnings</h3>
                                        <p><span style={{fontWeight: 'bold', color: '#14f195'}}>{formattedDividendsDistributed}<br></br> $MANNY</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.buttonContainer}>
                                <div className={styles.containerButton}>
                                    <div className={styles.interactiveButton} >
                                        <h3>Growth</h3>
                                        <p><span style={{fontWeight: 'bold', color: '#14f195'}}>{growthPercentage}%<br></br>$MANNY</span></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                </div>
            <Footer />
        </div>
    );
};

export default EarningsDashboard;
